import { Injectable } from '@angular/core';
import {BehaviorSubject, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchByNameService {

	// Open/closed status of the search by name widget
	isOpen = new BehaviorSubject(false);

	open = () => this.isOpen.next(true);
	close = () => {
		this.reset();
		this.isOpen.next(false);
	}
	toggle = () => {
		if (this.isOpen.value) {
			this.close();
		} else {
			this.open();
		}
	}

	// Map of strings to search against
	_searchMap = new Map<number|string, string>();
	get searchMap() { return this._searchMap; }
	set searchMap(value) {
		this._searchMap = value;
	}

	// Search text that the user enters
	_searchText = '';
	get searchText() { return this._searchText }
	set searchText(searchText) {
		this._searchText = searchText;
		this.findMatchingItems(this.searchText);
	}

	reset = () => this.searchText = '';

	// Array of all matching item keys
	matchingItemKeys = new BehaviorSubject<Array<number|string>>([]);

	// Selected matching item number
	selectedMatchingKey = new ReplaySubject<number|string|null>(1);
	selectedMatchingItemNumber = 0;

	// Sets the selected matching item number
	setSelectedMatchingItemNumber(itemNum: number) {
		this.selectedMatchingItemNumber = itemNum;
		this.selectedMatchingKey.next(itemNum !== 0 ? this.matchingItemKeys.value[itemNum - 1] : null);
	}

	// Increments the selected matching item number
	incrementSelectedMatchingItem = (increment: number) => {
		if (this.selectedMatchingItemNumber !== 0) {
			const newItemNumber = Math.min(this.matchingItemKeys.value.length, Math.max(1, this.selectedMatchingItemNumber + increment));
			this.setSelectedMatchingItemNumber(newItemNumber);
		}
	};

	/**
	 * Given a search string with multiple words (delimited by spaces),
	 * find all matching items in the search map.
	 *
	 * Items must match all words in the search text.
	 *
	 * We also reset the current, selected matching item back to 1 (or 0 if there are no matching items)
 	 *
	 * @param searchText
	 */
	findMatchingItems(searchText: string) {
		// Handle empty searchText
		if (!searchText) {
			this.matchingItemKeys.next([]);
			this.setSelectedMatchingItemNumber(0);
			return;
		}

		// Parse searchText into words array
		const words = searchText.split(' ');

		// Find map items that match ALL words in the search text and set the array of matching keys
		const matchingKeys: Array<number|string> = []
		this.searchMap.forEach((value, key) => {
			if (words.every(word => value.toLowerCase().includes(word.toLowerCase()))) {
				matchingKeys.push(key);
			}
		});

		this.matchingItemKeys.next(matchingKeys);

		// Set the selected item to 1 if there are any matches
		this.setSelectedMatchingItemNumber(Math.max(0, Math.min(1, matchingKeys.length)));

	}

}
