<div #selector *ngIf="visibleFilteredGovTypeList">
	<app-search class="search" [placeholder]="'Filter by name'" [(value)]="govTypeNameSearchValue"></app-search>
	<!-- Keep the radio group simple and we can rely on the browser standard controls to avoid having to manage aria & keyboard nav -->
	<div role="radiogroup" class="list">
		<div><input
			[id]="ALL"
			[value]="ALL"
			(change)="handleSelectionChange($event)"
			name="govType"
			type="radio"
			[checked]="selectedGovTypeCode === ALL"
		><label [for]="ALL">All government types</label></div>
		<hr class="divider"/>
		@for (govType of visibleFilteredGovTypeList; track $index) {
			<div [class.disabled]="govType.financialsDatasetSource === DatasetSource.None">
				<input
					[id]="govType.code"
					[value]="govType.code"
					(change)="handleSelectionChange($event)"
					name="govType"
					type="radio"
					[checked]="selectedGovTypeCode === govType.code"
					[disabled]="govType.financialsDatasetSource === DatasetSource.None"
				><label [for]="govType.code">{{govType.descriptionPlural}}</label>
			</div>
		}
	</div>
	<div class="show-more" *ngIf="this.filteredGovTypeList.length > itemsToDisplay">
		<button class="small outline" (click)="addPage()">Show more</button>
	</div>
</div>
