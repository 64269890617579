import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {LiveWithDetail} from './api/fit-api/models/datasets/live';
import {SnapshotWithDetail} from './api/fit-api/models/datasets/snapshot';
import {DatasetDetail} from './api/fit-api/models/datasets/dataset-type';
import {GovernmentId} from './api/fit-api/models/government-id';
import {DatasetSource} from './api/fit-api/models/datasets/dataset-source';
import {Report, ReportId, RouteParam, UserAccessLevel} from '../models/report';
import {FinancialService} from './api/fit-api/financial.service';
import {NoData} from '../models/no-data';
import {FitApiService} from './api/fit-api/fit-api.service';
import {GovernmentSpecificity} from './api/fit-api/models/government-specificity';
import {AnnualFilingDataset} from './api/fit-api/models/datasets/annual-filing-dataset-detail';
import {ALLFUNDS, AllFundsDataRow} from './api/fit-api/models/all-funds';
import {Schedule9} from './api/fit-api/models/schedule9';
import {OSPILongTermLiabilityRow} from './api/fit-api/models/schools/long-term-liability-row';
import {HintInformation} from '../models/hintInformation';
import {Schedule1AggregationByGovt} from './api/fit-api/models/schedule1-aggregation-by-govt';
import {Schedule1AggregationByGovType} from './api/fit-api/models/schedule1-aggregation-by-gov-type';
import {FinancialReportService} from '../pages/financial-report/financial-report.service';
import {FundField, SelectedFundNode} from '../components/funds-selector/fund-node-model';
import {SchoolsWithDetail} from './api/fit-api/models/datasets/schools';
import {FundTypeId} from './api/fit-api/models/funds/fund-type';
import {ALL_COUNTIES, SelectedCounties} from '../components/counties-selector/counties-model';
import {FinancialReportAggregationByGovt} from './api/fit-api/models/schools/financial-report-aggregation-by-govt';
import {
	FinancialReportAggregationByGovType
} from './api/fit-api/models/schools/financial-report-aggregation-by-gov-type';

@Injectable({
	providedIn: 'root'
})
export class FinancialSummaryService {
	public static barsHierarchy = ['fsSectionId', 'basicAccountId', 'subAccountId', 'elementId', 'subElementId'];
	public static baseFundHierarchy = ['fundCategoryId', 'fundTypeId'];

	public readonly REPORTS: ReadonlyArray<Readonly<Report<never>>> = [
		/* LGFRS-SAO Annual Filing Reports */
		{
			id: ReportId.revenues,
			name: 'Revenues',
			financialDatasetSource: DatasetSource.AnnualFiling,
			routeParam:  RouteParam.revenues,
			amountField: 'totalAmount',
			amountColor: 'green',
			filterLevel: 'fsSectionId', filterValues: [20],
			sortOrder: 20,
			hint: HintInformation.FinancialReport.Revenues,
			hasGrandTotals: true,
			columnHierarchyFunction: this.financialReportService.annualFilingSch1ColumnHierarchy.bind(this.financialReportService),
			rowHierarchyFunction: this.financialReportService.fsSectionBarsHierarchy.bind(this.financialReportService),
			dataSource: this.getAnnualFilingSch1FinancialReportData.bind(this)
		} as Report<AllFundsDataRow<Schedule1AggregationByGovt> | AllFundsDataRow<Schedule1AggregationByGovType>>,
		{
			id: ReportId.expenditures,
			name: 'Expenditures',
			financialDatasetSource: DatasetSource.AnnualFiling,
			routeParam:  RouteParam.expenditures,
			amountField: 'totalAmount',
			amountColor: 'purple',
			filterLevel: 'fsSectionId', filterValues: [30],
			sortOrder: 30,
			hint: HintInformation.FinancialReport.Expenditures,
			hasGrandTotals: true,
			columnHierarchyFunction: this.financialReportService.annualFilingSch1ColumnHierarchy.bind(this.financialReportService),
			rowHierarchyFunction: this.financialReportService.fsSectionBarsHierarchy.bind(this.financialReportService),
			dataSource: this.getAnnualFilingSch1FinancialReportData.bind(this)
		}  as Report<AllFundsDataRow<Schedule1AggregationByGovt> | AllFundsDataRow<Schedule1AggregationByGovType>>,
		{
			id: ReportId.debtAndLiabilities,
			name: 'Debt & Liabilities',
			financialDatasetSource: DatasetSource.AnnualFiling,
			routeParam:  RouteParam.debtLiabilities,
			amountField: 'ending',
			amountColor: 'blue',
			restrictedTo: UserAccessLevel.mcag,
			//filterLevel
			sortOrder: 40,
			hint: HintInformation.FinancialReport.DebtAndLiabilities,
			hasGrandTotals: true,
			columnHierarchyFunction: this.financialReportService.annualFilingSch9ColumnHierarchy.bind(this.financialReportService),
			rowHierarchyFunction: this.financialReportService.annualFilingDebtCategoriesHierarchy.bind(this.financialReportService),
			dataSource: this.getAnnualFilingSch9FinancialReportData.bind(this)
		} as Report<AllFundsDataRow<Schedule9>>,
		{
			id: ReportId.summary,
			name: 'Financial Summary',
			financialDatasetSource: DatasetSource.AnnualFiling,
			routeParam: RouteParam.summary,
			amountField: 'totalAmount',
			//amountColor - none for summary report
			filterLevel: 'fsSectionId', filterValues: [10, 20, 25, 30, 35, 40], // leaves off balance sheet
			sortOrder: 0,
			hint: HintInformation.FinancialReport.Summary,
			hasGrandTotals: false,
			columnHierarchyFunction: this.financialReportService.annualFilingSch1ColumnHierarchy.bind(this.financialReportService),
			rowHierarchyFunction: this.financialReportService.fsSectionBarsHierarchy.bind(this.financialReportService),
			dataSource: this.getAnnualFilingSch1FinancialReportData.bind(this)
		}  as Report<AllFundsDataRow<Schedule1AggregationByGovt> | AllFundsDataRow<Schedule1AggregationByGovType>>,
		/* OSPI-Schools Reports */
		{
			id: ReportId.schoolsRevenues,
			name: 'Revenues',
			financialDatasetSource: DatasetSource.OSPI,
			routeParam: RouteParam.revenues,
			amountField: 'totalAmount',
			amountColor: 'green',
			filterLevel: 'fsSectionId', filterValues: [20],
			sortOrder: 20,
			hint: HintInformation.FinancialReport.Revenues,
			hasGrandTotals: true,
			columnHierarchyFunction: this.financialReportService.schoolsFundsHierarchy.bind(this.financialReportService),
			rowHierarchyFunction: this.financialReportService.fsSectionBarsHierarchy.bind(this.financialReportService),
			dataSource: this.getOspiFinancialReportData.bind(this)
		} as Report<AllFundsDataRow<FinancialReportAggregationByGovt> | AllFundsDataRow<FinancialReportAggregationByGovType>>,
		{
			id: ReportId.schoolsExpenditures,
			name: 'Expenditures',
			financialDatasetSource: DatasetSource.OSPI,
			routeParam: RouteParam.expenditures,
			amountField: 'totalAmount',
			amountColor: 'purple',
			filterLevel: 'fsSectionId', filterValues: [30],
			sortOrder: 30,
			hint: HintInformation.FinancialReport.Expenditures,
			hasGrandTotals: true,
			columnHierarchyFunction: this.financialReportService.schoolsFundsHierarchy.bind(this.financialReportService),
			rowHierarchyFunction: this.financialReportService.fsSectionBarsHierarchy.bind(this.financialReportService),
			dataSource: this.getOspiFinancialReportData.bind(this)
		} as Report<AllFundsDataRow<FinancialReportAggregationByGovt> | AllFundsDataRow<FinancialReportAggregationByGovType>>,
		{
			id: ReportId.schoolsDebtAndLiabilities,
			name: 'Debt & Liabilities',
			financialDatasetSource: DatasetSource.OSPI,
			routeParam: RouteParam.debtLiabilities,
			amountField: 'amount',
			amountColor: 'blue',
			restrictedTo: UserAccessLevel.mcag,
			sortOrder: 40,
			hint: HintInformation.FinancialReport.DebtAndLiabilities,
			hasGrandTotals: true,
			columnHierarchyFunction: this.financialReportService.schoolsDebtAndLiabilitiesFundsHierarchy.bind(this.financialReportService),
			rowHierarchyFunction: this.financialReportService.schoolsDebtCategoriesHierarchy.bind(this.financialReportService),
			dataSource: this.getOspiLongTermLiabilitiesData.bind(this)
		} as Report<AllFundsDataRow<OSPILongTermLiabilityRow>>,
		{
			id: ReportId.schoolsSummary,
			name: 'Financial Summary',
			financialDatasetSource: DatasetSource.OSPI,
			routeParam: RouteParam.summary,
			amountField: 'totalAmount',
			//amountColor - none for summary report
			filterLevel: 'fsSectionId', filterValues: [10, 20, 25, 30, 35, 40], // leaves off balance sheet
			sortOrder: 0,
			hint: HintInformation.FinancialReport.Summary,
			hasGrandTotals: false,
			columnHierarchyFunction: this.financialReportService.schoolsFundsHierarchy.bind(this.financialReportService),
			rowHierarchyFunction: this.financialReportService.fsSectionBarsHierarchy.bind(this.financialReportService),
			dataSource: this.getOspiFinancialReportData.bind(this)
		} as Report<AllFundsDataRow<FinancialReportAggregationByGovt> | AllFundsDataRow<FinancialReportAggregationByGovType>>,

		// for financial report page (not available as a finances-at-a-glance card)
		// When you want to add these to pivot table, create pivot defs and connect to these report definitions.
		/* LGFRS-SAO Annual Filing Reports */
		{
			id: ReportId.revenuesAndOtherIncreases,
			name: 'Revenues and Other Increases',
			financialDatasetSource: DatasetSource.AnnualFiling,
			routeParam: RouteParam.revenuesOtherIncreases,
			amountField: 'totalAmount',
			amountColor: 'green',
			filterLevel: 'fsSectionId', filterValues: [20, 25],
			sortOrder: 25,
			hasGrandTotals: true,
			columnHierarchyFunction: this.financialReportService.annualFilingSch1ColumnHierarchy.bind(this.financialReportService),
			rowHierarchyFunction: this.financialReportService.fsSectionBarsHierarchy.bind(this.financialReportService),
			dataSource: this.getAnnualFilingSch1FinancialReportData.bind(this)
		}  as Report<AllFundsDataRow<Schedule1AggregationByGovt> | AllFundsDataRow<Schedule1AggregationByGovType>>,
		{
			id: ReportId.expendituresAndOtherDecreases,
			name: 'Expenditures and Other Decreases',
			financialDatasetSource: DatasetSource.AnnualFiling,
			routeParam: RouteParam.expendituresOtherDecreases,
			amountField: 'totalAmount',
			amountColor: 'purple',
			filterLevel: 'fsSectionId', filterValues: [30, 35],
			sortOrder: 30,
			hasGrandTotals: true,
			columnHierarchyFunction: this.financialReportService.annualFilingSch1ColumnHierarchy.bind(this.financialReportService),
			rowHierarchyFunction: this.financialReportService.fsSectionBarsHierarchy.bind(this.financialReportService),
			dataSource: this.getAnnualFilingSch1FinancialReportData.bind(this)
		}  as Report<AllFundsDataRow<Schedule1AggregationByGovt> | AllFundsDataRow<Schedule1AggregationByGovType>>,
		/* OSPI-Schools Reports */
		{
			id: ReportId.schoolsRevenuesAndOtherIncreases,
			name: 'Revenues and Other Increases',
			financialDatasetSource: DatasetSource.OSPI,
			routeParam: RouteParam.revenuesOtherIncreases,
			amountField: 'totalAmount',
			amountColor: 'green',
			filterLevel: 'fsSectionId', filterValues: [20, 25],
			sortOrder: 25,
			hasGrandTotals: true,
			columnHierarchyFunction: this.financialReportService.schoolsFundsHierarchy.bind(this.financialReportService),
			rowHierarchyFunction: this.financialReportService.fsSectionBarsHierarchy.bind(this.financialReportService),
			dataSource: this.getOspiFinancialReportData.bind(this)
		} as Report<AllFundsDataRow<FinancialReportAggregationByGovt> | AllFundsDataRow<FinancialReportAggregationByGovType>>,
		{
			id: ReportId.schoolsExpendituresAndOtherDecreases,
			name: 'Expenditures and Other Decreases',
			financialDatasetSource: DatasetSource.OSPI,
			routeParam: RouteParam.expendituresOtherDecreases,
			amountField: 'totalAmount',
			amountColor: 'purple',
			filterLevel: 'fsSectionId', filterValues: [30, 35],
			sortOrder: 35,
			hasGrandTotals: true,
			columnHierarchyFunction: this.financialReportService.schoolsFundsHierarchy.bind(this.financialReportService),
			rowHierarchyFunction: this.financialReportService.fsSectionBarsHierarchy.bind(this.financialReportService),
			dataSource: this.getOspiFinancialReportData.bind(this)
		} as Report<AllFundsDataRow<FinancialReportAggregationByGovt> | AllFundsDataRow<FinancialReportAggregationByGovType>>,

	];

	constructor(private financialService: FinancialService,
				private fitApiService: FitApiService,
				private financialReportService: FinancialReportService
	) {	}

	// No Data Scenario 1 (No financials dataset source or OSPI)
	getDatasetNoDataContent(source: Observable<DatasetSource>): Observable<NoData | undefined> {
		return source
			.pipe(map(source => {
					if (source === DatasetSource.None) {
						return { message: 'This government type does not submit online filing data and is therefore not displayed in FIT.' };
					} else {
						return undefined;
					}
				}
			));
	}

	/**
	 * Determines the year of data to be displayed from a Dataset (e.g., latest) for displays that only show a single year.
	 *  aka "Current Filing Year for Display"
	 * Spec: https://docs.google.com/document/d/1IRpZG6Ig4OXS6uiGFct6JDKeieWrzs3FkSfcJtrGwIY/edit#bookmark=id.xwgciz2ikdo8
	 * @param dataset
	 * @param governmentId
	 */
	getFiscalYearForDisplay(
		dataset: DatasetDetail | null,
		governmentId?: GovernmentId
	): Observable<number | null> {
		// Evaluate FilingStatus-based fiscal year logic for Live and Snapshot
		if (dataset instanceof AnnualFilingDataset) {
			if (governmentId?.specificity === GovernmentSpecificity.Government) {
				return this.fitApiService.getFilingStatuses(dataset, `${governmentId.parameterName} eq '${governmentId}'`).pipe(
					map(filingStatuses => {
						const filingStatusForBarsYear = filingStatuses.find(x =>
							x.year === dataset.barsYearUsed
						);
						if (filingStatusForBarsYear === undefined) {
							throw new Error(`No filing status found for ${dataset.barsYearUsed}`);
						}
						if (dataset.dateCreated > filingStatusForBarsYear.maxAllowedSubmitDate) {
							return filingStatusForBarsYear.year;
						} else {
							if (filingStatusForBarsYear.dateSubmitted) {
								return filingStatusForBarsYear.year;
							} else {
								return filingStatusForBarsYear?.year - 1;
							}
						}
					})
				);
			} else {  // For gov types & other scenarios that aggregate multiple MCAGs, use BARS Year of the dataset
				return of(dataset.barsYearUsed);
			}
			// for everything else, just return the max of includedYears
		} else if (dataset === null) {
			return of(null);
		} else {
			if (!dataset.detail.includedYears.length) {
				throw new Error(`No includedYears from which to choose from.`);
			}
			return of(
				Math.max(...dataset.detail.includedYears)
			);
		}
	}

	/**
	 * Create query string to pass to schedule 1 aggregation by government filter
	 * @param datasetDetail
	 * @param endYear
	 * @param startYear
	 * @param queryOverrides
	 */
	getSchedule1AggregationsByGovt(
		datasetDetail: SnapshotWithDetail | LiveWithDetail,
		endYear: number,
		startYear: number,
		queryOverrides: Map<string | undefined, string | undefined>
	): Observable<Array<Schedule1AggregationByGovt>> {
		const filterString = this.generateFilterStringForBarsAggregations(datasetDetail, endYear, startYear, queryOverrides, GovernmentSpecificity.Government)
		return this.financialService.getSchedule1AggregationsByGovt(datasetDetail, filterString);
	}

	/**
	 * Create query string to pass to schedule 1 aggregation by government type filter
	 * @param datasetDetail
	 * @param endYear
	 * @param startYear
	 * @param queryOverrides
	 */
	getSchedule1AggregationsByGovType(
		datasetDetail: SnapshotWithDetail | LiveWithDetail,
		endYear: number,
		startYear: number,
		queryOverrides: Map<string | undefined, string | undefined>
	): Observable<Array<Schedule1AggregationByGovType>> {
		const filterString = this.generateFilterStringForBarsAggregations(datasetDetail, endYear, startYear, queryOverrides, GovernmentSpecificity.GovernmentType)
		return this.financialService.getSchedule1AggregationsByGovType(datasetDetail, filterString);
	}

	/**
	 * Create filter for all Bars based aggregations
	 * Start with default query strings set to null and replace filters that are provided by the query override map.
	 * Stringify results, add year range string, and pass query string to aggregation filter
	 * @param datasetDetail
	 * @param endYear
	 * @param startYear
	 * @param queryOverrides
	 * @param governmentSpecificity - indicate how to treat/build the filter string (mcag & fund are not fields for the "By Gov Type" scenarios)
	 */
	generateFilterStringForBarsAggregations(
		datasetDetail: DatasetDetail | null,
		endYear: number,
		startYear: number,
		queryOverrides: Map<string | undefined, string | undefined>,
		governmentSpecificity: GovernmentSpecificity)
	{
		// if no query filter overrides exist, throw error
		if (!queryOverrides) {
			throw new Error(`No filter parameters were provided to aggregations`);
		}

		// start and end year string to overrides
		const yearRangeQueryString = `${startYear} le year and year le ${endYear} `;

		let queryDefaultFields = FinancialSummaryService.barsHierarchy.concat([
			'govTypeCode', 'countyCode', 'fundCategoryId', 'fundTypeId'
		]);

		// Add dataset specific fields to the default list
		if (datasetDetail instanceof SnapshotWithDetail || datasetDetail instanceof LiveWithDetail) {
			queryDefaultFields.push('fund', 'expenditureObjectId');
		} else if (datasetDetail instanceof SchoolsWithDetail) {
			queryDefaultFields.push('fundCode');
		}

		// If running a "By Gov Type" aggregation scenario, remove mcag and fund from the default fields
		queryDefaultFields = governmentSpecificity === GovernmentSpecificity.GovernmentType
			? queryDefaultFields.filter(f => f !== 'fund' && f !== 'fundCode') // Fund does not exist on the By Gov Type endpoint
			: queryDefaultFields.filter(f => f !== 'govTypeCode' && f !== 'countyCode'); // Gov Type & County Code are never null on the By Govt endpoint

		// Build default field list where no override exists
		const defaultFieldFilters = queryDefaultFields
			.filter(field => queryOverrides.get(field) === undefined)
			.map(field => `${field} eq null`).join(' and ');

		// Build list of override field filters
		const overrideFieldFilters = Array.from(queryOverrides, ([field, value]) => `${field} ${value}`).join(' and ');

		if (defaultFieldFilters.length) {
			return defaultFieldFilters.concat(' and ', overrideFieldFilters).concat(' and ', yearRangeQueryString);
		} else {
			return overrideFieldFilters.concat(' and ', yearRangeQueryString);
		}

	}

	/**
	 * Create query string to pass to OSPI aggregation by government filter
	 * @param datasetDetail
	 * @param endYear
	 * @param startYear
	 * @param queryOverrides
	 */
	getOSPIFinancialReportAggregationsByGovt(
		datasetDetail: SchoolsWithDetail,
		endYear: number,
		startYear: number,
		queryOverrides: Map<string | undefined, string | undefined>
	): Observable<Array<FinancialReportAggregationByGovt>> {
		const filterString = this.generateFilterStringForBarsAggregations(datasetDetail, endYear, startYear, queryOverrides, GovernmentSpecificity.Government)
		return this.financialService.getOSPIAggregationsByGovt(datasetDetail, filterString);
	}

	/**
	 * Create query string to pass to OSPI aggregation by government type filter
	 * @param datasetDetail
	 * @param endYear
	 * @param startYear
	 * @param queryOverrides
	 */
	getOSPIFinancialReportAggregationsByGovType(
		datasetDetail: SchoolsWithDetail,
		endYear: number,
		startYear: number,
		queryOverrides: Map<string | undefined, string | undefined>
	): Observable<Array<FinancialReportAggregationByGovType>> {
		const filterString = this.generateFilterStringForBarsAggregations(datasetDetail, endYear, startYear, queryOverrides, GovernmentSpecificity.GovernmentType)
		return this.financialService.getOSPIAggregationsByGovType(datasetDetail, filterString);
	}

	/**
	 *
	 * @param datasetDetail
	 * @param report
	 * @param governmentId
	 * @param startYear
	 * @param endYear
	 * @param fundNode
	 * @param excludeInternalService
	 * @param counties
	 *
	 */
	getOspiFinancialReportData<T extends
		AllFundsDataRow<FinancialReportAggregationByGovt> |
		AllFundsDataRow<FinancialReportAggregationByGovType>>
	(
		datasetDetail: DatasetDetail | null,
		report: Report<T>,
		governmentId: GovernmentId,
		startYear: number,
		endYear: number,
		fundNode?: SelectedFundNode,
		excludeInternalService = true,
		counties?: SelectedCounties
): Observable<Array<T>>
	{
		// Prepare OSPI Aggregation query filters - we want all FSSection & BARS Account level detail
		const queryOverrides = new Map(FinancialSummaryService.barsHierarchy.map(level => [level, `ne null`]));

		// Request expenditure object detail in case we need it for the applicable display option
		// n/a for Schools

		// Set the report defining filter level (usually FSSectionId)
		queryOverrides.set(report?.filterLevel as string, `in (${report?.filterValues?.join(',')})`);

		// Set the base fund query filters - we want all the fund hierarchy detail
		FinancialSummaryService.baseFundHierarchy.forEach(level => queryOverrides.set(level, `ne null`));

		// Set the base fund level override when we are viewing an individual government
		if (governmentId.specificity === GovernmentSpecificity.Government) {
			queryOverrides.set(`fundCode`, `ne null`);
		}

		// Set filter to exclude internal service records
		if (excludeInternalService) {
			queryOverrides.set(`fundTypeId`, `ne null and fundTypeId ne ${FundTypeId.internalService}`);
		}

		// Add fundNode filter - we do this after setting the internal service exclusion as this might override that Map value
		if (fundNode && fundNode.field !== FundField.allFunds) {
			const fundFieldValue = fundNode.field === FundField.fund ? `'${fundNode.value}'` : fundNode.value;
			queryOverrides.set(fundNode.field, `eq ${fundFieldValue}`);
		}

		// Set the counties filter if it is set
		if (counties && counties !== 'all') {
			queryOverrides.set(`countyCode`, `in (${counties.join(',')})`);
		}

		// Set the government id
		queryOverrides.set(governmentId.parameterName as string, `eq '${governmentId}'`);

		if (datasetDetail instanceof SchoolsWithDetail) {
			/// return data source observable
			let dataSource = of([] as Array<T>);
			if (governmentId.specificity === GovernmentSpecificity.Government) {
				dataSource = this.getOSPIFinancialReportAggregationsByGovt(datasetDetail, endYear, startYear, queryOverrides)
					.pipe(map(x => x.map(r => {
						return { ...r, ...ALLFUNDS } as T
					})));
			} else if (governmentId.specificity === GovernmentSpecificity.GovernmentType) {
				dataSource = this.getOSPIFinancialReportAggregationsByGovType(datasetDetail, endYear, startYear, queryOverrides)
					.pipe(map(x => x.map(r => {
						return { ...r, ...ALLFUNDS } as T
					})));
			}
			return dataSource;
		} else {
			throw new Error(`No logic defined for this dataset ${datasetDetail} or report not found`);
		}
	}

	getAnnualFilingSch1FinancialReportData<T extends
		AllFundsDataRow<Schedule1AggregationByGovt> |
		AllFundsDataRow<Schedule1AggregationByGovType>>
	(
		datasetDetail: DatasetDetail | null,
		report: Report<T>,
		governmentId: GovernmentId,
		startYear: number,
		endYear: number,
		fundNode?: SelectedFundNode,
		excludeInternalService = true,
		counties?: SelectedCounties): Observable<Array<T>>
	{
		// Prepare Schedule 1 Aggregation query filters - we want all FSSection & BARS Account level detail
		const queryOverrides = new Map(FinancialSummaryService.barsHierarchy.map(level => [level, `ne null`]));

		// Request expenditure object detail in case we need it for the applicable display option
		queryOverrides.set('expenditureObjectId', `ne null`);

		// Set the report defining filter level (usually FSSectionId)
		queryOverrides.set(report?.filterLevel as string, `in (${report?.filterValues?.join(',')})`);

		// Set the base fund query filters - we want all the fund hierarchy detail
		FinancialSummaryService.baseFundHierarchy.forEach(level => queryOverrides.set(level, `ne null`));

		// Set the base fund level override when we are viewing an individual government
		if (governmentId.specificity === GovernmentSpecificity.Government) {
			queryOverrides.set(`fund`, `ne null`);
		}

		// Set filter to exclude internal service records
		if (excludeInternalService) {
			queryOverrides.set(`fundTypeId`, `ne null and fundTypeId ne ${FundTypeId.internalService}`);
		}

		// Add fundNode filter - we do this after setting the internal service exclusion as this might override that Map value
		if (fundNode && fundNode.field !== FundField.allFunds) {
			const fundFieldValue = fundNode.field === FundField.fund ? `'${fundNode.value}'` : fundNode.value;
			queryOverrides.set(fundNode.field, `eq ${fundFieldValue}`);
		}

		// Set the counties filter if it is set
		if (counties && counties !== ALL_COUNTIES) {
			queryOverrides.set(`countyCode`, `in (${counties.join(',')})`);
		}

		// Set the government id
		queryOverrides.set(governmentId.parameterName as string, `eq '${governmentId}'`);

		if ((datasetDetail instanceof LiveWithDetail || datasetDetail instanceof SnapshotWithDetail)) {
			/// return data source observable
			let dataSource = of([] as Array<T>);
			if (governmentId.specificity === GovernmentSpecificity.Government) {
				dataSource = this.getSchedule1AggregationsByGovt(datasetDetail, endYear, startYear, queryOverrides)
					.pipe(map(x => x.map(r => {
						return { ...r, ...ALLFUNDS } as T
					})));
			} else if (governmentId.specificity === GovernmentSpecificity.GovernmentType) {
				dataSource = this.getSchedule1AggregationsByGovType(datasetDetail, endYear, startYear, queryOverrides)
					.pipe(map(x => x.map(r => {
						return { ...r, ...ALLFUNDS } as T
					})));
			}
			return dataSource;
		} else {
			throw new Error(`No logic defined for this dataset ${datasetDetail} or report not found`);
		}
	}

	getAnnualFilingSch9FinancialReportData<T extends AllFundsDataRow<Schedule9>>
	(
		datasetDetail: DatasetDetail | null,
		report: Report<T>,
		governmentId: GovernmentId,
		startYear: number,
		endYear: number): Observable<Array<T>>
	{
		if ((datasetDetail instanceof LiveWithDetail || datasetDetail instanceof SnapshotWithDetail)) {
			const filter = `${startYear} le year and year le ${endYear}`
				+ ` and ${governmentId.parameterName} eq '${governmentId}'`
				+ ` and ending ne null and ending ne 0`;

			// No fund filters for Schedule 9. There is only "All funds"

			// todo - set county codes filter

			return this.financialService.getSchedule9Totals(datasetDetail, filter)
				.pipe(map(x => x.map(r => {
					return { ...r, ...ALLFUNDS } as T
				})));
		} else {
			throw new Error(`No logic defined for this dataset ${datasetDetail} or report not found`);
		}
	}

	getOspiLongTermLiabilitiesData<T extends AllFundsDataRow<OSPILongTermLiabilityRow>>
	(
		datasetDetail: DatasetDetail | null,
		report: Report<T>,
		governmentId: GovernmentId,
		startYear: number,
		endYear: number): Observable<Array<T>>
	{
		if (datasetDetail instanceof SchoolsWithDetail) {
			const filter = FinancialSummaryService.getOSPILongTermLiabilityFilter(startYear, endYear);

			// todo - fund filters for LTL

			// todo - set county codes filter

			return this.financialService.getOSPILongTermLiabilities(datasetDetail, governmentId, filter)
				.pipe(map(x => x.map(r => {
					return { ...r, ...ALLFUNDS } as T
				})));
		} else {
			throw new Error(`No logic defined for this dataset ${datasetDetail} or report not found`);
		}
	}

	public static getOSPILongTermLiabilityFilter(startYear: number, endYear: number) {
		return `${startYear} le fy and fy le ${endYear}`
			+ ` and reportNo eq '013' and itemType eq 'Detail' and ColumnName eq 'Ending Outstanding Debt'`
			+ ` and Amount ne 0 and Amount ne null`;
	}

	/**
	 * Get latest year range from dataset detail using specified number of trend years
	 * @param datasetDetail
	 */
	getLatestYearsFromDataset(datasetDetail: DatasetDetail): number[] {
		datasetDetail?.detail.includedYears.sort((a, b) => a - b);
		return datasetDetail?.detail.includedYears.slice(datasetDetail?.detail.includedYears.length - FinancialService.NUM_TREND_YEARS, datasetDetail?.detail.includedYears.length);
	}

	/**
	 * Get start year range from dataset detail
	 * @param datasetDetail
	 */
	getStartYearFromDataset = (datasetDetail: DatasetDetail): number => this.getLatestYearsFromDataset(datasetDetail)[0];


	/**
	 * Get end year from dataset detail
	 * @param datasetDetail
	 */
	getEndYearFromDataset = (datasetDetail: DatasetDetail): number => {
		const trendYears = this.getLatestYearsFromDataset(datasetDetail);
		return trendYears[trendYears.length - 1];
	}
}
