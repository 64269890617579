<app-progress-bar [isVisible]="(isLoading | async) ?? false"></app-progress-bar>
<app-corner
	class="horizontal-shadow"
	[class.shadow-visible]="isContentScrolled || isNavigationScrolled"
	(launchMenu)="isMenuVisible = true"
	[class.content-scrolled]="isContentScrolled"
	[class.navigation-scrolled]="isNavigationScrolled"
></app-corner>
@if (searchByNameService.isOpen | async) {
	<div class="search-by-name horizontal-shadow"
		 [class.shadow-visible]="isContentScrolled"
		 [class.content-scrolled]="isContentScrolled"
	>
		<app-search-by-name></app-search-by-name>
	</div>
} @else {
	<app-header	class="horizontal-shadow"
	   [class.shadow-visible]="isContentScrolled"
	   [class.content-scrolled]="isContentScrolled"
	></app-header>
	<div *ngIf="!isSpaciousLayout" class="omni-search-mobile"
		 [class.content-scrolled]="isContentScrolled"
	>
		<!--
			This should be ngIf'd like in the header (or consolidated) to avoid issues with hotkeys (can only focus one
			element at a time). It is not currently causing issues, but is something to look out for.
		-->
		<app-omni-search [compactMode]="true"></app-omni-search>
	</div>
}
<app-navigation
	[class.visible]="isMenuVisible"
	(closeMenu)="isMenuVisible = false"
></app-navigation>
<app-content
></app-content>

