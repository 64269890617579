import {afterNextRender, Component, HostListener, OnDestroy, ViewChild} from '@angular/core';
import {SearchByNameService} from './search-by-name.service';
import {SearchComponent} from '../inputs/search/search.component';
import {SvgIconComponent} from '@ngneat/svg-icon';
import {LoadingIndicatorComponent} from '../../layout/loading-indicator/loading-indicator.component';
import {filter} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {Key} from 'ts-key-enum';

@Component({
	selector: 'app-search-by-name',
	standalone: true,
	imports: [
		SearchComponent,
		SvgIconComponent,
		LoadingIndicatorComponent
	],
	templateUrl: './search-by-name.component.html',
	styleUrl: './search-by-name.component.scss'
})
export class SearchByNameComponent implements OnDestroy {

	@ViewChild(SearchComponent) searchComponent?: SearchComponent;

	// When widget opens, reset the search input and set focus there
	isOpen$ = this.service.isOpen.pipe(filter(isOpen => isOpen))
		.subscribe(() => {
			afterNextRender(() => this.searchComponent?.focus());
		});

	// Handle up/down arrow keyboard nav to step through matched items
	@HostListener('keyup', ['$event'])
	onKeyUp(event: KeyboardEvent) {
		// Hide Popover when Escape key is released
		if (event.key === Key.Escape) {
			this.service.close();
		} else if (event.key === Key.ArrowDown) {
			this.service.incrementSelectedMatchingItem(+1);
		} else if (event.key === Key.ArrowUp) {
			this.service.incrementSelectedMatchingItem(-1);
		}
	}

	// When we navigate away from the page, close the widget
	routerEvents$ = this.router.events.pipe(
		filter(event => event instanceof NavigationStart)
	).subscribe(() => this.service.close());

	constructor(
		private router: Router,
		protected service: SearchByNameService,
	) {	}

	ngOnDestroy() {
		this.isOpen$.unsubscribe();
		this.routerEvents$.unsubscribe();
	}
}
