import {
	afterRender,
	AfterRenderPhase,
	Component, ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges, ViewChild
} from '@angular/core';
import {NgIf} from '@angular/common';
import {ToggleComponent} from '../inputs/toggle/toggle.component';
import {GovernmentType} from '../../services/api/fit-api/models/government-type';
import {GovTypeCode} from '../../services/api/fit-api/models/gov-type-code';
import {FitApiService} from '../../services/api/fit-api/fit-api.service';
import {DatasetSource} from '../../services/api/fit-api/models/datasets/dataset-source';
import {sortByProperty} from '../../services/functions/sort-by-property';
import {SearchComponent} from '../inputs/search/search.component';

export type ALL_GOVT_TYPES = 'All types';

@Component({
  selector: 'app-government-type-selector',
  standalone: true,
	imports: [
		NgIf,
		ToggleComponent,
		SearchComponent
	],
  templateUrl: './government-type-selector.component.html',
  styleUrl: './government-type-selector.component.scss'
})
export class GovernmentTypeSelectorComponent implements OnChanges, OnDestroy {
	static ALL_GOVT_TYPES: ALL_GOVT_TYPES = 'All types';

	@Input() selectedGovTypeCode: ALL_GOVT_TYPES | GovTypeCode = GovernmentTypeSelectorComponent.ALL_GOVT_TYPES;
	@Output() selectedGovTypeCodeChange = new EventEmitter<ALL_GOVT_TYPES | GovTypeCode>();

	// emit selected value label (for display)
	@Output() governmentTypeLabel = new EventEmitter<string>();

	@ViewChild('selector') selectorElement?: ElementRef<HTMLElement>;

	govTypeList: Array<GovernmentType> = [];
	filteredGovTypeList: Array<GovernmentType> = [];
	visibleFilteredGovTypeList: Array<GovernmentType> = [];

	// paging
	readonly PAGE_SIZE = 10;
	private _itemsToDisplay = 10;
	get itemsToDisplay() { return this._itemsToDisplay; }
	set itemsToDisplay(num: number) {
		this._itemsToDisplay = num;
		this.updateVisibleGovTypeList();
	}
	addPage() {
		this.itemsToDisplay = Math.min(this.itemsToDisplay + this.PAGE_SIZE, this.filteredGovTypeList.length);
	}

	// gov type name filter
	// notes: trigger govTypeNameFilter() logic everytime countyNameSearchValue changes (instead of on emit valueChange()),
	// this ensures logic will run everytime the search value changes (e.g. cases like backspacing on last search value character)
	private _govTypeNameSearchValue = '';
	get govTypeNameSearchValue() {
		return this._govTypeNameSearchValue;
	}
	set govTypeNameSearchValue(value: string) {
		this._govTypeNameSearchValue = value;
		this.filterGovTypeNameList();
	}

	constructor(
		private fitApi: FitApiService,
	) {
		// Pagination changes can leave the gov type list without an element that has tabIndex=0
		// If missing a child element with tabIndex=0, then restore to the default grid element (name search/filter)
		afterRender(() => {
			const focusableElement = this.selectorElement?.nativeElement.querySelector('[tabindex="0"]');
			if (!focusableElement) { // No focusable element found, set to description area cell
				const descAreaElement = this.selectorElement?.nativeElement.querySelector('.search');
				if (descAreaElement) {
					descAreaElement.setAttribute('tabIndex', '0');
				}
			}
		}, {phase: AfterRenderPhase.Write});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['selectedGovTypeCode']) {
			this.selectionChanged(this.selectedGovTypeCode);
			this.setCheckedValueInRadioGroup();
		}
	}

	setCheckedValueInRadioGroup() {
		const ele = document.getElementsByName('govType');
		for(let i=0;i<ele.length;i++) {
			const element = ele[i] as HTMLInputElement;
			if (element.id === this.selectedGovTypeCode) {
				element.checked = true;
			}
		}
	}

	govTypeList$ = this.fitApi.governmentTypesForList.subscribe((governmentTypes) => {
		this.govTypeList = governmentTypes.sort(sortByProperty(gt => gt.descriptionPlural));
		this.filterGovTypeNameList();
	});

	filterGovTypeNameList() {
		this.filteredGovTypeList = this.govTypeList.filter(govType =>
			!this.govTypeNameSearchValue || govType.descriptionPlural.toLowerCase().includes(this.govTypeNameSearchValue.toLowerCase())
		);
		this.updateVisibleGovTypeList();
	}

	updateVisibleGovTypeList() {
		this.visibleFilteredGovTypeList = this.filteredGovTypeList.slice(0, this.itemsToDisplay - 1);
	}

	selectionChanged(selection: ALL_GOVT_TYPES | GovTypeCode) {
		this.selectedGovTypeCodeChange.emit(selection);
		this.updateGovernmentTypeLabel(selection);
	}

	updateGovernmentTypeLabel(selection: ALL_GOVT_TYPES | GovTypeCode) {
		let label: string = selection;
		if (selection !== GovernmentTypeSelectorComponent.ALL_GOVT_TYPES) {
			const govType = this.govTypeList.find(gt => gt.code === selection)
			label = govType?.descriptionPlural ?? 'label not found';
		}
		this.governmentTypeLabel.emit(label);
	}

	handleSelectionChange(event: Event): void {
		const selectedValue = (event.target as HTMLInputElement).value;
		if (selectedValue) {
			this.selectionChanged(selectedValue as ALL_GOVT_TYPES | GovTypeCode);
		}
	}

	// public function for parent component
	public resetControls() {
		this.govTypeNameSearchValue = ''; // set filter value to empty string
		this.itemsToDisplay = this.PAGE_SIZE;
	}

	ngOnDestroy() {
		this.govTypeList$?.unsubscribe();
	}

	ALL = GovernmentTypeSelectorComponent.ALL_GOVT_TYPES;
	protected readonly DatasetSource = DatasetSource;

}
