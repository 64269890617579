import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FileService} from './file.service';
import {LoggerService} from './logger.service';
import {combineLatest, Observable, Subject, Subscription, switchMap, takeUntil} from 'rxjs';
import {DatasetDetail} from './api/fit-api/models/datasets/dataset-type';
import {MCAG} from './api/fit-api/models/mcag';
import {SnapshotWithDetail} from './api/fit-api/models/datasets/snapshot';
import {SchoolsWithDetail} from './api/fit-api/models/datasets/schools';
import Excel from '@nbelyh/exceljs';
import {PivotTable} from '../models/pivot-table/pivot-table';
import {PivotRow, PivotRowType} from '../models/pivot-table/pivot-row';
import {PivotCellType} from '../models/pivot-table/pivot-cell';
import {PivotAnnotationType} from '../models/pivot-table/pivot-annotation';
import {saveAs} from 'file-saver';
import {PivotDisplayUnit} from '../models/pivot-table/pivot-display-unit';

@Injectable({
	providedIn: 'root'
})
export class DownloadService {
	NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS = 5;

	constructor(
		private http: HttpClient,
		private fileService: FileService,
		private logger: LoggerService
	) {
	}

	// 'Download XLSX' extracts (FH Profile Extracts)
	getFinancialHealthExtractDownload(
		snapshotId: number,
		govTypeCode: string | null,
		countyCode: string | null,
		outlook: string | null,
		name: string | null,
		startYear: number | null,
		endYear: number | null,
	) {
		const call = `Downloads/FinancialHealthDownload(snapshotId=${snapshotId},govTypeCode=${govTypeCode},countyCode=${countyCode},outlook=${outlook},govName=${name},startYear=${startYear},endYear=${endYear})`;
		this.fileService.download(call).subscribe(response => {
			// log file download state
			this.logger.info(response);
		});
	}

	// 'Download XLSX' Finances-at-a-Glance extract
	getPlaceholderExtractDownload<T extends object>(
		pivotTable: PivotTable<T>,
		workbook: Excel.Workbook,
		worksheetName: string,   // e.g. Finances-at-a-Glance, Taxes By Govt
		fileNameBase: string,    // e.g. Finances, Taxes
		publishedDate: string | null, // todo should be Date type
		title: string,
		annotation: Observable<string>,
		startYear: Observable<number>,
		endYear: Observable<number>,
		downloadUnsubscribe: Subject<void>,
	): Subscription {
		return combineLatest([annotation, startYear, endYear])
			.pipe(switchMap(([annotation, startYear, endYear]) => {
			// angular has problem with encoding characters, see https://stackoverflow.com/questions/49438737/how-to-escape-angular-httpparams
			const encodedTitle = encodeURIComponent(title); // for Cities/Towns case, encode backslash correctly
			let encodedAnnotation = encodeURIComponent(annotation); // for Cities/Towns case, encode backslash correctly
			if (encodedAnnotation.length === 0) {
				encodedAnnotation = '\'\'';  // Passed empty string parameter as annotation=''
			}
			const call = `Downloads/PlaceholderForDownload(worksheetName=${worksheetName},fileNameBase=${fileNameBase},publishedDate=${publishedDate},title=${encodedTitle},annotation=${encodedAnnotation},startYear=${startYear},endYear=${endYear})`;
			return this.http.get(call, {responseType: 'blob'});
		}), takeUntil(downloadUnsubscribe)).subscribe(blob => {
				// Open file with exceljs or other library
				const fileReader = new FileReader();
				fileReader.readAsArrayBuffer(blob);
				fileReader.onload = async () => {
					const arrayBuffer = fileReader.result as ArrayBuffer;
					if (arrayBuffer) {
						await workbook.xlsx.load(arrayBuffer);
					}
					// Add data from pivot VM to file
					const worksheet = workbook.getWorksheet(worksheetName);

					if (worksheet && pivotTable) {
						this.writePivotTableRowsToWorksheet(worksheet, pivotTable);
					} else {
						throw Error('Could not retrieve placeholder worksheet');
					}

					workbook.clearThemes();  //Corrupt file unless I do this here... https://github.com/exceljs/exceljs/issues/745#issuecomment-1688375934

					// Fixes issue with hyperlink not working on TOC worksheet once Finances-at-a-Glance data is added
					const tocWorksheet = workbook.getWorksheet('Table of Contents');
					const sheetName = worksheetName;
					const sheetRef = `#'${sheetName}'!A1`;
					tocWorksheet.getCell('A8').value = {text: sheetName, hyperlink: sheetRef};

					// Return file to browser
					workbook.xlsx.writeBuffer().then((data) => {
						const blob = new Blob([data], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						});

						const fileName = ['SAO_FIT_Download', fileNameBase, new Date().toISOString().split('T')[0]]
						saveAs(blob, fileName.join('_'));
						downloadUnsubscribe.next();
					});
				}
			});
	}

	// 'Download Report Indicator' FHI w/ individual indicators
	getIndicatorDownload(
		startYear: number,
		endYear: number,
		datasetDetail: DatasetDetail,
		mcag?: MCAG
	) {
		if (datasetDetail instanceof SnapshotWithDetail) {
			const call = `Downloads/IndicatorDownload(snapshotId=${datasetDetail.id},mcag=${mcag},startYear=${startYear},endYear=${endYear})`;
			this.fileService.download(call).subscribe(response => {
				// log file download state
				this.logger.info(response);
			});
		} else if (datasetDetail instanceof SchoolsWithDetail) {
			const call = `Downloads/IndicatorDownloadForSchool(mcag=${mcag},startYear=${startYear},endYear=${endYear})`;
			this.fileService.download(call).subscribe(response => {
				// log file download state
				this.logger.info(response);
			});
		} else {
			throw Error ('Not a recognized dataset for Indicator Download.');
		}
	}

	//region Download Extract
	/**
	 * Write Pivot Table Data Rows to Excel Worksheet
	 * See mockup: https://docs.google.com/spreadsheets/d/1DQlc4_yoe6Z6Pq1SuV759PMkyVfDPc2MNspB_rvAB9o/edit#gid=0
	 *
	 * Steps to write table:
	 *  1 - Find place marker cell to start writing table to
	 *  2 - Write header rows (if normalization row exists in first row add another row)
	 *	3 - Write data rows (if child rows exist, call write-to function recursively)
	 *	4 - Write total rows
	 *
	 * @param worksheet
	 * @param pivotTable
	 */
	writePivotTableRowsToWorksheet<T extends object>(
		worksheet: Excel.Worksheet,
		pivotTable: PivotTable<T>
	) {
		// find placeholder, set first cell in pivot table
		const placeholderCell = this.findPlaceHolderCell(worksheet);
		// track the current row in Excel that we are writing to; set initially to the row of the placeholder cell
		let currentExcelRowNumber = placeholderCell.row;
		// column number of the placeholder cell; represents the left most column for our grid
		const firstColumnNumber = placeholderCell.col;

		// iterate over pivot table data
		pivotTable.rows.forEach((pivotTableRow, pivotTableRowIndex) => { // iterate each row of data
			switch (pivotTableRow.type) {
				case PivotRowType.header: {
					// first row of pivot table data
					const isFirstPivotTableRow = pivotTableRowIndex === 0;
					currentExcelRowNumber = this.writeHeaderRow(pivotTableRow, worksheet, firstColumnNumber, currentExcelRowNumber, isFirstPivotTableRow);
					break;
				}
				case PivotRowType.data:
					currentExcelRowNumber = this.writeDataRow(pivotTableRow, worksheet, firstColumnNumber, currentExcelRowNumber);
					break;
				case PivotRowType.total:
					currentExcelRowNumber = this.writeTotalRow(pivotTableRow, worksheet, firstColumnNumber, currentExcelRowNumber);
					break;
				default:
					break;
			}

			// iterate to next Excel row to write to
			currentExcelRowNumber++;
		});

	}

	/**
	 * Write Header Row Cells to Excel Worksheet
	 *
	 * @param pivotTableRow
	 * @param worksheet
	 * @param firstColumnNumber
	 * @param currentExcelRowNumber
	 * @param isFirstPivotTableRow
	 * @return currentExcelRowNumber
	 */
	writeHeaderRow<T extends object>(
		pivotTableRow: PivotRow<T>,
		worksheet: Excel.Worksheet,
		firstColumnNumber: number,
		currentExcelRowNumber: number,
		isFirstPivotTableRow: boolean
	): number {
		// test if header row, log an error and return row number (skips row)
		if (pivotTableRow.type !== PivotRowType.header) {
			this.logger.warn('Pivot table row type not header');
			return currentExcelRowNumber;
		}

		// is this row the first pivot table row? and find if normalization annotation exists on any cells in row
		const normalizationRowExists = isFirstPivotTableRow
			&& pivotTableRow.cells.some(cell => (cell.type === PivotCellType.columnHeader) && cell.firstAnnotationOfType(PivotAnnotationType.normalization));

		// track the current column in Excel that we are writing to or have just finished writing to
		let currentExcelColumnNumber = firstColumnNumber;

		// iterate on cells
		for (const [pivotTableCellIndex, pivotTableCell] of pivotTableRow.cells.entries()) {
			if (!pivotTableCell.isHeader) {
				this.logger.warn('Pivot table cell type not header cell');
				break;
			} else {

				// is Excel cell writeable?
				if (!this.isExcelCellWriteable(worksheet, currentExcelRowNumber, currentExcelColumnNumber)) {
					currentExcelColumnNumber = this.nextExcelColumnNumberToWriteTo(worksheet, currentExcelRowNumber, currentExcelColumnNumber);
				}

				// set Excel cell to write to
				let currentExcelCellToWriteTo = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber);

				// if first row
				if (isFirstPivotTableRow) {
					// if normalization row add 1 to rowspan; will be added another excel row for normalization annotation values
					let effectiveRowSpan = pivotTableCell.rowSpan + (normalizationRowExists ? 1 : 0);

					// if cell type is empty (area description)
					if (pivotTableCell.type === PivotCellType.descriptionArea) {
						// establish what needs to be in category header text
						let displayUnit = pivotTableCell.firstAnnotationOfType(PivotAnnotationType.displayUnit)?.text;
						if (displayUnit == PivotDisplayUnit.thousands || displayUnit == PivotDisplayUnit.millions) {
							displayUnit = PivotDisplayUnit.dollars;
						}
						const categoryHeaderText = displayUnit && effectiveRowSpan === 1
							? pivotTableCell.displayValue + '/' + displayUnit
							: pivotTableCell.displayValue;

						// set actual current Excel row to keep track of, if there is a rowspan greater than 1 and display unit annotation is present
						const actualCurrentExcelRowNumber = currentExcelRowNumber;

						// if row span more than 1 and there is a display unit
						if (effectiveRowSpan > 1 && displayUnit) {
							// set value display code
							currentExcelCellToWriteTo.value = displayUnit;
							// merge cell
							worksheet.mergeCells(currentExcelRowNumber, currentExcelColumnNumber, currentExcelRowNumber + (effectiveRowSpan - 2), currentExcelColumnNumber + (this.NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS - 1));
							// set styling
							this.styleCellForHeaderAndGrandTotal(currentExcelCellToWriteTo);
							this.setAlignment(currentExcelCellToWriteTo, 'center', 'middle');

							// set current excel row to write to
							currentExcelRowNumber += (effectiveRowSpan - 1);

							// set effective row span
							effectiveRowSpan = 1;
						}
						// set cell to write to
						currentExcelCellToWriteTo = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber);

						// set value + display code
						currentExcelCellToWriteTo.value = categoryHeaderText;
						// merge cell
						worksheet.mergeCells(currentExcelRowNumber, currentExcelColumnNumber, currentExcelRowNumber + (effectiveRowSpan - 1), currentExcelColumnNumber + (this.NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS - 1));
						// set styling
						this.styleCellForHeaderAndGrandTotal(currentExcelCellToWriteTo);
						// note: no need to align - automatically aligns to the left

						// set current excel row number back to actual row
						// note: will be the same number if above conditional doesn't trigger
						currentExcelRowNumber = actualCurrentExcelRowNumber;

						// increment current column number
						currentExcelColumnNumber += (this.NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS - 1);

						// set last column in area description width
						worksheet.getColumn(currentExcelColumnNumber).width = 36;

						// else if cell type is column header
					} else if (pivotTableCell.type === PivotCellType.columnHeader) {
						// set column header widths
						worksheet.getColumn(currentExcelColumnNumber).width = 18;

						// set value
						currentExcelCellToWriteTo.value = pivotTableCell.displayValue;

						// if colspan - merge cells
						worksheet.mergeCells(currentExcelRowNumber, currentExcelColumnNumber, currentExcelRowNumber, currentExcelColumnNumber + (pivotTableCell.columnSpan - 1));

						// if normalization row exists, write to it
						if (normalizationRowExists) {
							const cellBelow = this.getCellBelow(worksheet, currentExcelRowNumber, currentExcelColumnNumber);
							cellBelow.value = pivotTableCell.firstAnnotationOfType(PivotAnnotationType.normalization)?.text;
							worksheet.mergeCells(parseInt(cellBelow.row), currentExcelColumnNumber, parseInt(cellBelow.row), currentExcelColumnNumber + (pivotTableCell.columnSpan - 1));

							// set style on cell below
							this.styleCellForHeaderAndGrandTotal(cellBelow);
							this.setAlignment(cellBelow, 'center', 'middle');
						}

						// if missing filing annotation exists, write it below normalization annotation (if it exists)
						if (pivotTableCell.firstAnnotationOfType(PivotAnnotationType.missingFilingReason)) {
							const howManyRowsBelow = normalizationRowExists ? 2 : 1;
							const cellBelow = this.getCellBelow(worksheet, currentExcelRowNumber, currentExcelColumnNumber, howManyRowsBelow);
							cellBelow.value = pivotTableCell.firstAnnotationOfType(PivotAnnotationType.missingFilingReason)?.text;
							worksheet.mergeCells(parseInt(cellBelow.row), currentExcelColumnNumber, parseInt(cellBelow.row) + (pivotTableCell.rowSpan - 2), currentExcelColumnNumber);

							// set style on cell below
							this.styleCellForHeaderAndGrandTotal(cellBelow);
							this.setAlignment(cellBelow, 'center', 'middle');
						}

						// set styling on cell
						this.styleCellForHeaderAndGrandTotal(currentExcelCellToWriteTo);
						this.setAlignment(currentExcelCellToWriteTo, 'center', 'middle');

						// set current column
						currentExcelColumnNumber += (pivotTableCell.columnSpan - 1);

						// if last cell in first row set new current row to write to based on if there is an annotation row
						if (pivotTableRow.cells.length - 1 === pivotTableCellIndex) {
							currentExcelRowNumber += normalizationRowExists ? 1 : 0;
						}

						// else error - skip cell
					} else {
						this.logger.warn('Pivot table cell type not found');
						break;
					}

					// else any other row but first row
				} else {
					// if column header
					if (pivotTableCell.type === PivotCellType.columnHeader) {
						// set column header widths
						worksheet.getColumn(currentExcelColumnNumber).width = 18

						// set value
						currentExcelCellToWriteTo.value = pivotTableCell.displayValue;
						worksheet.mergeCells(currentExcelRowNumber, currentExcelColumnNumber, currentExcelRowNumber + (pivotTableCell.rowSpan - 1), currentExcelColumnNumber + (pivotTableCell.columnSpan - 1));

						// set style
						this.styleCellForHeaderAndGrandTotal(currentExcelCellToWriteTo);
						this.setAlignment(currentExcelCellToWriteTo, 'center', 'middle');

						// set current column
						currentExcelColumnNumber += (pivotTableCell.columnSpan - 1);

						// else error
					} else {
						this.logger.warn('Pivot table cell type not found');
						break;
					}
				}
			}

			// iterate to next excel column to write to
			currentExcelColumnNumber = this.nextExcelColumnNumberToWriteTo(worksheet, currentExcelRowNumber, currentExcelColumnNumber);
		}

		return currentExcelRowNumber;
	}

	/**
	 * Write Data Row Cells to Excel Worksheet
	 *
	 * @param pivotTableRow
	 * @param worksheet
	 * @param firstColumnNumber
	 * @param currentExcelRowNumber
	 * @return currentExcelRowNumber
	 */
	writeDataRow<T extends object>(
		pivotTableRow: PivotRow<T>,
		worksheet: Excel.Worksheet,
		firstColumnNumber: number,
		currentExcelRowNumber: number
	): number {
		// track the current column in Excel that we are writing to
		let currentExcelColumnNumber = firstColumnNumber;

		for (const [pivotTableCellIndex, pivotTableCell] of pivotTableRow.cells.entries()) {
			// is Excel cell writeable?
			if (!this.isExcelCellWriteable(worksheet, currentExcelRowNumber, currentExcelColumnNumber)) {
				currentExcelColumnNumber = this.nextExcelColumnNumberToWriteTo(worksheet, currentExcelRowNumber, currentExcelColumnNumber);
			}

			// set Excel cell to write to
			let currentExcelCellToWriteTo = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber);

			// if first cell in row
			if (pivotTableCellIndex === 0) {
				if (pivotTableCell.isHeader) {
					// set cell to write to based on level
					currentExcelCellToWriteTo = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber + ((pivotTableRow.level ?? 1) - 1));
					// write value or value with account code annotation
					currentExcelCellToWriteTo.value = pivotTableCell.firstAnnotationOfType(PivotAnnotationType.accountCode)
						? pivotTableCell.displayValue + ` (${pivotTableCell.firstAnnotationOfType(PivotAnnotationType.accountCode)!.text})`
						: pivotTableCell.displayValue;
					// set current to column 5
					currentExcelColumnNumber = this.NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS;
					// style every cell in row
					this.styleBackgroundColorForRowHeader(worksheet, firstColumnNumber, currentExcelRowNumber, 'data');
				} else {
					this.logger.warn('Pivot table cell type not found');
					break;
				}
			} else {
				if (pivotTableCell.type === PivotCellType.data) {
					const noDataAnnotation = pivotTableCell.annotations.find(a => a.type === PivotAnnotationType.noData);
					if (noDataAnnotation) {
						// set value
						currentExcelCellToWriteTo.value = noDataAnnotation.text;
					} else {
						// set value
						currentExcelCellToWriteTo.value = pivotTableCell.value;
						currentExcelCellToWriteTo.numFmt = pivotTableCell.excelNumberFormat;
					}
					// set alignment
					this.setAlignment(currentExcelCellToWriteTo, 'right');
				} else {
					this.logger.warn('Pivot table cell type not found');
					break;
				}
			}

			// set style
			this.styleCellForData(currentExcelCellToWriteTo);

			// iterate to next excel column to write to
			currentExcelColumnNumber = this.nextExcelColumnNumberToWriteTo(worksheet, currentExcelRowNumber, currentExcelColumnNumber);
		}

		// if child rows trigger recursion
		if (pivotTableRow.childRows) {
			for (let i = 0; i < pivotTableRow.childRows.length; i++) {
				currentExcelRowNumber++;
				currentExcelRowNumber = this.writeDataRow(pivotTableRow.childRows[i], worksheet, firstColumnNumber, currentExcelRowNumber);
			}
		}

		return currentExcelRowNumber;
	}

	/**
	 * Write Total Row Cells to Excel Worksheet
	 *
	 * @param pivotTableRow
	 * @param worksheet
	 * @param firstColumnNumber
	 * @param currentExcelRowNumber
	 * @return currentExcelRowNumber
	 */
	writeTotalRow<T extends object>(
		pivotTableRow: PivotRow<T>,
		worksheet: Excel.Worksheet,
		firstColumnNumber: number,
		currentExcelRowNumber: number
	): number {
		// track the current column in Excel that we are writing to
		let currentExcelColumnNumber = firstColumnNumber;

		for (const [pivotTableCellIndex, pivotTableCell] of pivotTableRow.cells.entries()) {
			// set Excel cell to write to
			const currentExcelCellToWriteTo = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber);

			// if first cell in row
			if (pivotTableCellIndex === 0) {
				if (pivotTableCell.isHeader) {
					// write value or value with account code annotation
					currentExcelCellToWriteTo.value = pivotTableCell.displayValue;
					// set current to column 5
					currentExcelColumnNumber = this.NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS;
					// style every cell in row
					this.styleBackgroundColorForRowHeader(worksheet, firstColumnNumber, currentExcelRowNumber, 'header');
				} else {
					this.logger.warn('Pivot table cell type not found');
					break;
				}
			} else {
				if (pivotTableCell.type === PivotCellType.data) {
					// set value
					currentExcelCellToWriteTo.value = pivotTableCell.value;
					currentExcelCellToWriteTo.numFmt = pivotTableCell.excelNumberFormat;
					// set alignment
					this.setAlignment(currentExcelCellToWriteTo, 'right');
				} else {
					this.logger.warn('Pivot table cell type not found');
					break;
				}
			}

			// set style
			this.styleCellForHeaderAndGrandTotal(currentExcelCellToWriteTo);

			// iterate to next excel column to write to
			currentExcelColumnNumber = this.nextExcelColumnNumberToWriteTo(worksheet, currentExcelRowNumber, currentExcelColumnNumber);
		}

		return currentExcelRowNumber;
	}

	/**
	 * Iterate to next cell to write to, test if its writeable
	 * Return excel column number to write to
	 *
	 * @param worksheet
	 * @param currentExcelRowNumber
	 * @param currentExcelColumnNumber
	 * @return currentExcelColumnNumber
	 */
	nextExcelColumnNumberToWriteTo(
		worksheet: Excel.Worksheet,
		currentExcelRowNumber: number,
		currentExcelColumnNumber: number
	): number {
		// iterate to next column
		currentExcelColumnNumber += 1;

		// is Excel writeable?
		const isExcelCellWriteable = this.isExcelCellWriteable(worksheet, currentExcelRowNumber, currentExcelColumnNumber);

		if (!isExcelCellWriteable) {
			// check over cells in row, write to the next cell that is not merged and doesn't have a value
			let i = 1, stopLoop = false;
			while (!stopLoop) {
				const c = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber + i);
				if (!c.isMerged && !c.value) {
					currentExcelColumnNumber = parseInt(c.col);
					stopLoop = true;
				}
				i++;
			}
		}

		return currentExcelColumnNumber;
	}

	/**
	 * Test if cell is writeable
	 *
	 * @param worksheet
	 * @param currentExcelRowNumber
	 * @param currentExcelColumnNumber
	 * @return boolean
	 */
	isExcelCellWriteable(
		worksheet: Excel.Worksheet,
		currentExcelRowNumber: number,
		currentExcelColumnNumber: number
	): boolean {
		const cellToWriteTo = worksheet.getRow(currentExcelRowNumber).getCell(currentExcelColumnNumber);
		return !(cellToWriteTo.isMerged || cellToWriteTo.value);
	}

	/**
	 * Style background color for every cell in pivot row headers
	 *
	 * @param worksheet
	 * @param firstColumnNumber
	 * @param currentCellRowNumber
	 * @param type
	 */
	styleBackgroundColorForRowHeader(
		worksheet: Excel.Worksheet,
		firstColumnNumber: number,
		currentCellRowNumber: number,
		type: 'header' | 'data'
	) {
		for (let i = 0; i < this.NUM_OF_EXCEL_COLUMNS_USED_BY_PIVOT_ROW_HEADERS; i++) {
			const cellToWriteTo = worksheet.getRow(currentCellRowNumber).getCell(firstColumnNumber + i);
			if (type === 'header') {
				this.styleCellForHeaderAndGrandTotal(cellToWriteTo);
			}

			if (type === 'data') {
				this.styleCellForData(cellToWriteTo);
			}
		}
	}

	// light gray
	styleCellForData(cell: Excel.Cell) {
		cell.fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: {argb: 'f3f3f3'},
		};
	}

	// blue
	styleCellForHeaderAndGrandTotal(cell: Excel.Cell) {
		cell.fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: {argb: '2459a9'},
		};

		cell.font = {
			color: {argb: 'FFFFFF'},
			bold: true
		};
	}

	// set alignment
	setAlignment(
		cellToWriteTo: Excel.Cell,
		horizontal: 'right' | 'center',
		vertical?: 'middle'
	) {
		if (vertical) {
			cellToWriteTo.alignment = {vertical: vertical, horizontal: horizontal}
		} else {
			cellToWriteTo.alignment = {horizontal: horizontal};
		}
	}

	/**
	 * Find placeholder cell
	 *
	 * @param worksheet
	 * @return placeholder
	 */
	findPlaceHolderCell(worksheet: Excel.Worksheet): { row: number, col: number } {
		let placeholder = {
			row: 1,
			col: 1
		};
		worksheet.eachRow(row =>
			row.eachCell(cell => {
				if (cell.value === '<grid goes here>') {
					cell.value = null; // allows for top left column (column 1) to be written correctly
					placeholder = {
						row: parseInt(cell.row),
						col: parseInt(cell.col)
					}
				}
			})
		)

		return placeholder;
	}

	getCellBelow = (
		worksheet: Excel.Worksheet,
		currentExcelRowNumber: number,
		currentExcelColumnNumber: number,
		howManyRowsBelow = 1
	) => worksheet.getRow(currentExcelRowNumber + howManyRowsBelow).getCell(currentExcelColumnNumber);

	//endregion
}
