<label *ngIf="label" [for]="elementId">{{ label }}</label>
<span class="input-container">
	<input
		#searchInput
		class="search-input"
		type="search"
		[id]="elementId"
		[placeholder]="placeholder"
		[value]="value"
		[attr.aria-label]="label ? null : placeholder"
	>
	@if (iconKey) {
		<svg-icon [key]="iconKey"></svg-icon>
	}
	@if (insetText !== undefined) {
		<span class="inset-text">{{insetText}}</span>
	}
</span>
