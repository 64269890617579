<h4 class="title">Search by name</h4>

<div class="controls">
	<div class="search-input">
		<app-search
			placeholder="Search by name"
			[insetText]="service.selectedMatchingItemNumber + ' of ' + service.matchingItemKeys.value.length"
			[(value)]="service.searchText"
		>
		</app-search>
	</div>

	<button class="next"
		aria-label="Next"
		[disabled]="service.selectedMatchingItemNumber === service.matchingItemKeys.value.length"
		(click)="service.incrementSelectedMatchingItem(+1)"
	>
		<svg-icon key="chevron-down" height="20px" width="20px"></svg-icon>
	</button>

	<button class="previous"
		aria-label="Previous"
		[disabled]="service.selectedMatchingItemNumber <= 1"
		(click)="service.incrementSelectedMatchingItem(-1)"
	>
		<svg-icon key="chevron-down" height="20px" width="20px"></svg-icon>
	</button>

	<button class="close"
		aria-label="Close Search by Name"
		(click)="service.close()"
	>
		<svg-icon key="close" height="20px" width="20px"></svg-icon>
	</button>

</div>




