import { Injectable } from '@angular/core';
import {combineLatest, distinctUntilChanged, ReplaySubject, startWith, Subject, withLatestFrom} from 'rxjs';
import {FinancialService} from './api/fit-api/financial.service';
import {YearRangeOptionalRouteParams} from '../models/route-params/year-range-optional-route-params';
import {PivotDisplayUnit} from '../models/pivot-table/pivot-display-unit';
import {DisplayUnitOptionalRouteParam} from '../models/route-params/display-unit-optional-route-param';
import {FundField, SelectedFundNode} from '../components/funds-selector/fund-node-model';
import {selectedFundNodeStringParser} from './functions/selected-fund-node-string-parser';
import {FundNodeOptionalRouteParams} from '../models/route-params/fund-node-optional-route-params';
import {ALL_COUNTIES, SelectedCounties} from '../components/counties-selector/counties-model';
import {match} from './functions/array/match';
import {selectedCountiesStringParser} from './functions/selected-counties-string-parser';
import {
	ALL_GOVT_TYPES,
	GovernmentTypeSelectorComponent
} from '../components/government-type-selector/government-type-selector.component';
import {GovTypeCode} from './api/fit-api/models/gov-type-code';
import {GovernmentId} from './api/fit-api/models/government-id';
import {GovernmentSpecificity} from './api/fit-api/models/government-specificity';
import {CountiesOptionalRouteParams} from '../models/route-params/counties-optional-route-param';
import {GovTypeOptionalRouteParams} from '../models/route-params/gov-type-optional-route-param';
import {map} from 'rxjs/operators';
import {FinancialReportOptionalRouteParams} from '../models/route-params/financial-report-optional-route-params';
import {County} from './api/fit-api/models/snapshots/county';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

	constructor() { }

	//region Optional Route Parameter Handling
	/**
	 * Start and end years for the Financial Report, Dollars / By Governments & Dollars / By Government Types pages.
	 */
	startYear = new ReplaySubject<number>(1);
	distinctStartYear = this.startYear.pipe(distinctUntilChanged());
	endYear = new ReplaySubject<number>(1);
	distinctEndYear = this.endYear.pipe(distinctUntilChanged());
	defaultYearRange: { start?: number, end?: number } = {};
	processStartYearRouteParam(startYear: string | null, endYear: number, fiscalYear: number, params: YearRangeOptionalRouteParams) {
		let appliedStartYear: number;
		delete params.startYear;
		this.defaultYearRange.start = fiscalYear - FinancialService.NUM_TREND_YEARS + 1;
		if (startYear) {
			const parsed = Number.parseInt(startYear);
			// Check if the parsing result is NaN (not a number)
			if (isNaN(parsed)) {
				throw new Error(`Invalid startYear: ${startYear} is not a valid integer.`);
			} else {
				appliedStartYear = parsed;
				params.startYear = appliedStartYear;
			}
		} else {
			if (endYear) {
				appliedStartYear = endYear - FinancialService.NUM_TREND_YEARS + 1;
			} else {
				appliedStartYear = this.defaultYearRange.start;
			}
		}
		this.startYear.next(appliedStartYear);
	}
	processEndYearRouteParam(endYear: string | null, fiscalYear: number, params: YearRangeOptionalRouteParams): number {
		let appliedEndYear: number;
		delete params.endYear;
		this.defaultYearRange.end = fiscalYear;
		if(endYear) {
			const parsed = Number.parseInt(endYear);
			// Check if the parsing result is NaN (not a number)
			if (isNaN(parsed)) {
				throw new Error(`Invalid endYear: ${endYear} is not a valid integer.`);
			} else {
				appliedEndYear = parsed;
				params.endYear = appliedEndYear;
			}
		} else {
			appliedEndYear = this.defaultYearRange.end; // default to fiscal year if none specified
		}
		this.endYear.next(appliedEndYear);
		return appliedEndYear;
	}

	/**
	 * Exclude internal service funds flag for the Financial Report, Dollars / By Governments & Dollars / By Government Types pages.
	 */
	excludeInternalServiceFunds = new ReplaySubject<boolean>(1);
	defaultExcludeInternalServiceFunds = true;
	distinctExcludeInternalServiceFunds = this.excludeInternalServiceFunds.pipe(distinctUntilChanged());
	processExcludeInternalServiceFundsRouteParam(excludeInternalServiceFunds: string | null, params: FundNodeOptionalRouteParams) {
		delete params.excludeInternalServiceFunds;
		if (excludeInternalServiceFunds) {
			const parsed =
				excludeInternalServiceFunds === 'true' ? true :
					excludeInternalServiceFunds === 'false' ? false :
						undefined;
			// Check if the parsing result was able to be parsed
			if (parsed === undefined) {
				throw new Error(`Invalid excludeInternalServiceFunds: ${excludeInternalServiceFunds} is not a valid boolean value.`);
			} else {
				this.excludeInternalServiceFunds.next(parsed);
				params.excludeInternalServiceFunds = parsed;
			}
		} else {
			this.excludeInternalServiceFunds.next(this.defaultExcludeInternalServiceFunds);  // Default if not specified
		}
	}

	/**
	 * Fund node filter for the Financial Report, Dollars / By Governments & Dollars / By Government Types pages.
	 */
	fundNode = new ReplaySubject<SelectedFundNode>(1);
	defaultFundNode = new SelectedFundNode('All funds', FundField.allFunds);
	distinctFundNode = this.fundNode.pipe(distinctUntilChanged(
		(a,b) => a.value === b.value && a.field === b.field
	));
	processFundNodeRouteParam(fundNode: string | null, params: FundNodeOptionalRouteParams) {
		delete params.fundNode;
		if (fundNode) {
			const parsed = selectedFundNodeStringParser(fundNode);
			// Check if the parsing result was able to be parsed
			if (parsed === undefined) {
				throw new Error(`Invalid fundNode: ${fundNode} is not a valid fund node value.`);
			} else {
				this.fundNode.next(parsed);
				params.fundNode = parsed.id;
			}
		} else {
			this.fundNode.next(this.defaultFundNode);  // Default if not specified
		}
	}

	/**
	 * Counties filter for the Financial Report, Dollars / By Governments & Dollars / By Government Types pages.
	 */
	counties = new ReplaySubject<SelectedCounties | undefined>(1);
	defaultCounties: SelectedCounties = ALL_COUNTIES;
	distinctCounties = this.counties.pipe(distinctUntilChanged(
		(a,b) =>
			(a === b) || (Array.isArray(a) && Array.isArray(b) && match(a,b))
	));
	processCountiesRouteParam(counties: string | null, enabled: boolean, params: CountiesOptionalRouteParams) {
		delete params.counties;
		if (counties && enabled) {
			const parsed = selectedCountiesStringParser(counties);
			// Check if the parsing result was able to be parsed
			if (parsed === undefined) {
				throw new Error(`Invalid counties: ${counties} is not a valid counties value.`);
			} else {
				this.counties.next(parsed);
				params.counties = parsed;
			}
		} else if (enabled) {
			this.counties.next(this.defaultCounties);  // Default if not specified
		} else {
			this.counties.next(undefined); // Undefined if not enabled for this page
		}
	}

	/**
	 * Government Type Code filter for the By Government page
	 */
	govTypeCode = new ReplaySubject<ALL_GOVT_TYPES | GovTypeCode | undefined>(1);
	defaultGovTypeCode = GovernmentTypeSelectorComponent.ALL_GOVT_TYPES;
	distinctGovTypeCode = this.govTypeCode.pipe(distinctUntilChanged());
	processGovTypeCodeRouteParam(govTypeCode: string | null, enabled: boolean, params: GovTypeOptionalRouteParams) {
		delete params.govTypeCode;
		if (govTypeCode && enabled) {
			const governmentId = new GovernmentId(govTypeCode as GovTypeCode);
			const isGovernmentType = governmentId.specificity === GovernmentSpecificity.GovernmentType;
			if (!isGovernmentType) {
				throw new Error(`Invalid gov type code: ${govTypeCode} is not a valid gov type code.`);
			}
			this.govTypeCode.next(govTypeCode as GovTypeCode);
			params.govTypeCode = govTypeCode as GovTypeCode;
		} else if (enabled) {
			this.govTypeCode.next(this.defaultGovTypeCode); // Default if not specified
		} else {
			this.govTypeCode.next(undefined);  // Undefined if not enabled for this page
		}
	}

	/**
	 * Display unit for the Financial Report, Dollars / By Governments & Dollars / By Government Types pages.
	 */
	displayUnit = new ReplaySubject<PivotDisplayUnit>(1);
	defaultDisplayUnit = PivotDisplayUnit.dollars;
	distinctDisplayUnit = this.displayUnit.pipe(distinctUntilChanged());
	distinctDisplayUnitForFetch = this.displayUnit.pipe(  // We need an observable that emits changes when a display unit change affects data fetching (to/from perCapita or perStudent)
		map(displayUnit => {
			if ( displayUnit === PivotDisplayUnit.perCapita || displayUnit === PivotDisplayUnit.perStudent) {
				return displayUnit;
			}
			return null;
		}), distinctUntilChanged()
	);
	processDisplayUnitRouteParam(displayUnit: string | null, params: DisplayUnitOptionalRouteParam) {
		delete params.displayUnit;
		if (displayUnit) {
			let parsed = PivotDisplayUnit[displayUnit as keyof typeof PivotDisplayUnit];
			const isValueOfEnum = Object.values(PivotDisplayUnit).some(value => displayUnit === value);
			if (parsed === undefined && isValueOfEnum ) {
				parsed = displayUnit as PivotDisplayUnit;
			}
			if (parsed) {
				this.displayUnit.next(parsed);
				params.displayUnit = parsed;
			} else {
				throw new Error(`Invalid displayUnit: ${displayUnit} is not a valid display unit.`);
			}
		} else {
			this.displayUnit.next(this.defaultDisplayUnit);  // Default if not specified
		}
	}

	/**
	 * Display options - Show account codes for the Financial Report page.
	 */
	showAccountNumbers = new ReplaySubject<boolean>(1);
	defaultShowAccountNumbers = false;
	distinctShowAccountNumbers = this.showAccountNumbers.pipe(distinctUntilChanged());
	processShowAccountNumbersRouteParam(showAccountNumbers: string | null, params: FinancialReportOptionalRouteParams) {
		delete params.showAccountNumbers;
		if (showAccountNumbers) {
			const parsed =
				showAccountNumbers === 'true' ? true :
					showAccountNumbers === 'false' ? false :
						undefined;
			// Check if the parsing result was able to be parsed
			if (parsed === undefined) {
				throw new Error(`Invalid showAccountNumbers: ${showAccountNumbers} is not a valid boolean value.`);
			} else {
				this.showAccountNumbers.next(parsed);
				params.showAccountNumbers = parsed;
			}
		} else {
			this.showAccountNumbers.next(this.defaultShowAccountNumbers);  // Default if not specified
		}
	}

	/**
	 * By Expenditure Objects option for the Financial Report page.
	 */
	byExpenditureObjects = new ReplaySubject<boolean | undefined>(1);
	defaultByExpenditureObjects = false;
	distinctByExpenditureObjects = this.byExpenditureObjects.pipe(distinctUntilChanged());
	processByExpenditureObjectsRouteParam(byExpenditureObjects: string | null, enabled: boolean, params: FinancialReportOptionalRouteParams) {
		delete params.byExpenditureObjects;
		if (byExpenditureObjects && enabled) {
			const parsed =
				byExpenditureObjects === 'true' ? true :
					byExpenditureObjects === 'false' ? false :
						undefined;
			// Check if the parsing result was able to be parsed
			if (parsed === undefined) {
				throw new Error(`Invalid byExpenditureObjects: ${byExpenditureObjects} is not a valid boolean value.`);
			} else {
				this.byExpenditureObjects.next(parsed);
				params.byExpenditureObjects = parsed;
			}
		} else if (enabled) {
			this.byExpenditureObjects.next(this.defaultByExpenditureObjects);  // Default if not specified
		} else {
			this.byExpenditureObjects.next(undefined); // Undefined if not enabled for this page
		}
	}

	//endregion

	//region Widgets/UI

	// Year range widget
	yearRangeStart?: number;
	yearRangeEnd?: number;
	selectedStartYear = new ReplaySubject<number>(1);
	selectedEndYear = new ReplaySubject<number>(1);
	appliedStartYear$ = this.distinctStartYear.subscribe(startYear =>
		this.startYearSelected(startYear)
	);
	appliedEndYear$ = this.distinctEndYear.subscribe(endYear =>
		this.endYearSelected(endYear)
	);
	startYearSelected = (event: number) => this.selectedStartYear.next(event);
	endYearSelected = (event: number)=> this.selectedEndYear.next(event);

	// Government Type selector widget
	isGovTypeSelectorOpen = false;
	selectedGovTypeCode = new ReplaySubject<ALL_GOVT_TYPES | GovTypeCode | undefined>(1);
	appliedGovTypeCode$ = this.distinctGovTypeCode.subscribe(govTypeCode =>
		this.selectedGovTypeCode.next(govTypeCode)
	);
	govTypeCodeSelected = (event: ALL_GOVT_TYPES | GovTypeCode | undefined) => this.selectedGovTypeCode.next(event);
	selectedGovTypeCodeLabel?: string;
	setSelectedGovTypeCodeLabel = (event: string) => this.selectedGovTypeCodeLabel = event;

	// Fund node selector widget
	isFundSelectorOpen = false;
	selectedFundNode = new ReplaySubject<SelectedFundNode>(1);
	appliedFundNode$ = this.distinctFundNode.subscribe(fundNode =>
		this.selectedFundNode.next(fundNode)
	);
	fundNodeSelected = (event: SelectedFundNode) => this.selectedFundNode.next(event);
	selectedFundNodeLabel?: string;
	setSelectedFundNodeLabel = (event: string)=> this.selectedFundNodeLabel = event;

	// Exclude internal service funds checkbox
	selectedExcludeInternalServiceFunds = new ReplaySubject<boolean>(1);
	appliedExcludeInternalServiceFunds$ = this.distinctExcludeInternalServiceFunds.subscribe(exclude =>
		this.selectedExcludeInternalServiceFunds.next(exclude)
	);
	excludeInternalServiceFundsSelected = (event: boolean) => this.selectedExcludeInternalServiceFunds.next(event);

	// Counties widget
	isCountiesOpen = false;
	selectedCounties = new ReplaySubject<SelectedCounties | undefined>(1);
	appliedCounties$ = this.distinctCounties.subscribe(counties =>
		this.selectedCounties.next(counties)
	);
	countiesSelected = (event: SelectedCounties | undefined) => this.selectedCounties.next(event);
	selectedCountiesLabel?: string;
	setSelectedCountiesLabel = (event: string)=> this.selectedCountiesLabel = event;

	// Display units widget
	isDisplayOptionsOpen = false;
	selectedDisplayUnit = new ReplaySubject<PivotDisplayUnit>(1);
	appliedDisplayUnit$ = this.distinctDisplayUnit.subscribe(displayUnit =>
		this.displayUnitSelected(displayUnit)
	);
	displayUnitSelected = (event: PivotDisplayUnit) => this.selectedDisplayUnit.next(event);
	selectedDisplayUnitLabel?: string;
	setSelectedDisplayUnitLabel = (event: string)=> this.selectedDisplayUnitLabel = event;

	// Show account numbers
	selectedShowAccountNumbers = new ReplaySubject<boolean | undefined>(1);
	appliedShowAccountNumbers$ = this.distinctShowAccountNumbers.subscribe(exclude =>
		this.selectedShowAccountNumbers.next(exclude)
	);
	showAccountNumbersSelected = (event: boolean) => this.selectedShowAccountNumbers.next(event);

	// Show account numbers
	selectedByExpenditureObjects = new ReplaySubject<boolean | undefined>(1);
	appliedByExpenditureObjects$ = this.distinctByExpenditureObjects.subscribe(byExpObjects =>
		this.selectedByExpenditureObjects.next(byExpObjects)
	);
	byExpenditureObjectsSelected = (event: boolean) => this.selectedByExpenditureObjects.next(event);

	//endregion

	// Handle closed emit function (set selected/transient values to applied)
	// notes:
	// - dialog can be closed in multiple ways, e.g: click outside of dialog, esc key, and close button)
	// - subjects with pipe(startWith(initialValue)) are used for replay subjects that haven't emitted initial values,
	// aren't used in some contexts of the application (e.g. show account numbers isn't used in the by dollars profile)
	close = new Subject<void>();
	closed = () => this.close.next(); // Executed when dialog is closed
	close$ = this.close.pipe(
		withLatestFrom(
			combineLatest([
				this.distinctStartYear,
				this.distinctEndYear,
				this.distinctGovTypeCode.pipe(startWith(undefined)),
				this.distinctFundNode,
				this.distinctExcludeInternalServiceFunds,
				this.distinctCounties,
				this.distinctDisplayUnit,
				this.distinctShowAccountNumbers.pipe(startWith(undefined)),
				this.distinctByExpenditureObjects.pipe(startWith(undefined))
			])
		), map(([, applied]) => applied)
	).subscribe(([startYear, endYear, govTypeCode, fundNode, excludeInternal, counties, displayUnit, showAccountNumbers, byExpenditureObjects]) => {
		// Reverts selected values back to current applied values
		this.selectedStartYear.next(startYear);
		this.selectedEndYear.next(endYear);
		this.selectedGovTypeCode.next(govTypeCode);
		this.selectedFundNode.next(fundNode);
		this.selectedExcludeInternalServiceFunds.next(excludeInternal);
		this.selectedCounties.next(counties);
		this.selectedDisplayUnit.next(displayUnit);
		this.selectedShowAccountNumbers.next(showAccountNumbers);
		this.selectedByExpenditureObjects.next(byExpenditureObjects);

		// Collapse all accordions
		this.isGovTypeSelectorOpen = false;
		this.isCountiesOpen = false;
		this.isDisplayOptionsOpen = false;
		this.isFundSelectorOpen = false;
	});

	generateCountiesAnnotation = (counties: SelectedCounties | undefined, countyNames: County[], value: string) => {
		if (counties && counties !== ALL_COUNTIES) {
			const countiesDesc = counties.map(c =>
				(countyNames.find(cn => cn.countyCode === c)?.countyName ?? c) + ' County'
			).join(', ')
			if (value.length) {
				return `; ${countiesDesc}`;
			} else {
				return `${countiesDesc}`;
			}
		} else {
			return '';
		}
	}

	generateInternalServiceFundsAnnotation = (excludeInternalServiceFunds: boolean, value: string) => {
		if (!excludeInternalServiceFunds) {
			if (value.length) {
				return `; net totals include internal service`;
			} else {
				return `Net totals include internal service`;
			}
		} else {
			return '';
		}
	}
}
