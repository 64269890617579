import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {FilingCondition, FilingStatus, FilingStatusesYearsAndTotals} from './api/fit-api/models/filing-status';
import {map} from 'rxjs/operators';
import {DatasetDetail} from './api/fit-api/models/datasets/dataset-type';
import {DatasetSource} from './api/fit-api/models/datasets/dataset-source';
import {FitApiService} from './api/fit-api/fit-api.service';
import {FilingStatusInfo} from '../models/filing-status-info';
import {LocalGovernment} from './api/fit-api/models/local-government';
import {SummaryFilingStatus} from '../models/summary-filing-status';
import {GovernmentId} from './api/fit-api/models/government-id';
import {ODataCollectionResult} from './api/odata-collection-result';
import {HttpClient} from '@angular/common/http';
import {sortByProperty} from './functions/sort-by-property';
import {Share} from './api/share';
import {AnnualFilingDataset} from './api/fit-api/models/datasets/annual-filing-dataset-detail';
import {countUntilChange} from './functions/array/count-until-change';

@Injectable({
	providedIn: 'root'
})
export class FilingStatusService {

	constructor(
		private fitApi: FitApiService,
		private http: HttpClient
	) {
	}

	// Used by different pivot grids summarization to determine which filing conditions to count as 'filer'
	readonly filerConditions = [FilingCondition.Timely, FilingCondition.Late, FilingCondition.Filed];

	/**
	 * Return filing status info in display format (icon, color, and display text)
	 * @param government
	 * @param datasetDetail
	 * @param displayYear
	 */
	getFilingStatusInfo(
		government?: LocalGovernment | null,
		datasetDetail?: DatasetDetail | null,
		displayYear?: number | null,
	): Observable<FilingStatusInfo | undefined> {
		if (!government) {
			return of(undefined);
		}

		// Handle OSPI
		if (government?.financialsDatasetSource === DatasetSource.OSPI) {
			return of({
				color: 'green',
				text: 'Filed with OSPI',
				consecutiveYearCount: 0,
				icon: 'done'
			});
		}

		if (datasetDetail instanceof AnnualFilingDataset) {
			const filingStatusFilter = `mcag eq '${government.governmentId}'&year le ${displayYear}&orderby=year desc`
			return this.fitApi.getFilingStatuses(datasetDetail, filingStatusFilter).pipe(map(filingStatuses => {
				const displayYearFilingStatus = filingStatuses.find(fs => fs.year === displayYear);
				const consecutiveYearCount = countUntilChange(filingStatuses, 'filingCondition');
				return this.createFilingStatusInfo(consecutiveYearCount, displayYearFilingStatus);
			}));
		}

		return of(undefined);
	}

	/**
	 * Return filing status display and text
	 * @param displayYearFilingStatus
	 * @param consecutiveYearCount
	 */
	createFilingStatusInfo = (consecutiveYearCount: number, displayYearFilingStatus?: FilingStatus): FilingStatusInfo => {
		let text;
		const innerText = displayYearFilingStatus?.filingCondition === FilingCondition.Timely ? 'Filed on time' : 'Has not filed';
		if (consecutiveYearCount > 9) {
			text = `${innerText} the past 9+ years`;
		} else if (consecutiveYearCount > 1) {
			text = `${innerText} the past ${consecutiveYearCount} years`
		} else {
			text = innerText;
		}

		switch (displayYearFilingStatus?.filingCondition) {
			case FilingCondition.Timely:
				return {
					color: 'green',
					text: text,
					consecutiveYearCount: consecutiveYearCount,
					icon: 'trophy',
					fs: displayYearFilingStatus
				};
			case FilingCondition.Late:
				return {
					color: 'orange',
					text: 'Filed late',
					consecutiveYearCount: consecutiveYearCount,
					icon: 'done',
					fs: displayYearFilingStatus
				};
			case FilingCondition.None:
				return {
					color: 'red',
					text: text,
					consecutiveYearCount: consecutiveYearCount,
					icon: 'warning',
					fs: displayYearFilingStatus
				};
			default:
				return {
					color: 'red',
					text: 'Not Active',
					consecutiveYearCount: 0,
					icon: 'warning'
				};
		}
	}

	/**
	 * Get a summarized filing status for All filers or optionally by GovType that includes the number of active governments, number that filed on
	 * time, and number that filed but have a pending update.
	 * @param datasetDetail
	 * @param displayYear
	 * @param govTypeCode
	 */
	getSummarizedFilingStatuses = (datasetDetail: DatasetDetail, displayYear: number, govTypeCode?: string): Observable<SummaryFilingStatus> => {
		let filter = `year eq ${displayYear}`;
		if (govTypeCode) {
			filter += ` and govTypeCode eq '${govTypeCode}'`;
		}
		return this.fitApi.getFilingStatuses(datasetDetail, filter).pipe(
			map(result =>
				result.reduce((counts, row) => {
					counts.addToSummary(row);
					return counts;
				}, new SummaryFilingStatus(displayYear))
			)
		);
	}

	/**
	 * Get Filing Statuses for year range
	 * @param datasetDetail
	 * @param startYear
	 * @param endYear
	 * @param governmentId
	 */
	getFilingStatusesForYearRange = (datasetDetail: DatasetDetail, startYear: number, endYear: number, governmentId?: GovernmentId): Observable<FilingStatus[]> => {
		let filter = `${startYear} le year and year le ${endYear}`;
		if (governmentId) {
			filter += ` and ${governmentId.parameterName} eq '${governmentId}'`;
		}
		return this.fitApi.getFilingStatuses(datasetDetail, filter);
	}

	/**
	 * Get list of number of filers per year given an amount of years given
	 * @param amountOfYearsToReturn
	 */
	@Share()
	getAnnualFilingStatusesYearsAndTotals(amountOfYearsToReturn?: number) {
		const params = `$apply=filter(datasetSource eq 'AnnualFiling' and dateSubmitted ne null and pendingUpdates eq false)/groupby((year),aggregate(mcag with countdistinct as total))`;
		const url = `FilingStatuses?${params}`;
		return this.http.get<ODataCollectionResult<FilingStatusesYearsAndTotals>>(url)
			.pipe(map((result) => {
				// extra data manipulation because of a known bug in OData
				// https://github.com/OData/AspNetCoreOData/issues/969
				// sortby and top would go in the Odata call but cannot at this time - JTC 7/23
				return result.value.sort(sortByProperty(x => x.year, false)).slice(0, (amountOfYearsToReturn ?? 2));
			}));
	}

}
